.modal-dialog.modal-lg{
  max-width: 80%;
}

.autocomplete-dropdown-container{
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  position: absolute;
  z-index: 9999;
  background-color: #fff;
  width: 100%;


  .suggestion-item{
    background-color: #fff;
    cursor: 'pointer';
    padding: 5px
  }
  .suggestion-item--active{
    background-color: #fafafa;
    cursor: 'pointer';
    padding: 5px
  }
}








.add_img_wrap {
  overflow: hidden;
  border-radius: 15px !important;
  border: 1px solid #ddd; 
  position: relative;
}
.add_img_wrap img { width: 100%; }
.add_img_wrap .caption {
  display: none;
  position: absolute;
  bottom: 0;
  background: #0005;
  width: 100%;
  text-align: center;
  color: #fff;
  padding: 5px;
  font-size: 25px;
  cursor: pointer; 
}
.add_img_wrap .caption label {
  margin: 0;
  cursor: pointer; 
}

.add_img_wrap:hover .caption {
  display: block; 
}
.add_img_wrap.card{
  padding-bottom: 0 !important;
}










.profile_img_wrap {
  // overflow: hidden;
  // border-radius: 15px !important;
  // border: 1px solid #ddd; 
  position: relative;
  border: 2px solid #50b179;
}
.profile_img_wrap img { width: 100%; }
.profile_img_wrap .caption {
  display: none;
  position: absolute;
  bottom: 0;
  background: #0005;
  width: 100%;
  text-align: center;
  color: #fff;
  padding: 5px;
  font-size: 25px;
  cursor: pointer; 
}
.profile_img_wrap .caption label {
  margin: 0;
  cursor: pointer; 
}

.profile_img_wrap:hover .caption {
  display: block; 
}
.profile_img_wrap.card{
  padding-bottom: 0 !important;
}
.restaurant_img_wrap {
  // overflow: hidden;
  // border-radius: 15px !important;
  // border: 1px solid #ddd; 
  position: relative;
  border: 1px solid gray;
}
.restaurant_img_wrap img { width: 100%; }
.restaurant_img_wrap .caption {
  display: none;
  position: absolute;
  bottom: 0;
  background: #0005;
  width: 100%;
  text-align: center;
  color: #fff;
  padding: 5px;
  font-size: 25px;
  cursor: pointer; 
}
.restaurant_img_wrap .caption label {
  margin: 0;
  cursor: pointer; 
}

.restaurant_img_wrap:hover .caption {
  display: block; 
}
.restaurant_img_wrap.card{
  padding-bottom: 0 !important;
}

// .react-select__menu{
  // position: absolute;
  // z-index: 999;
  // display: block;
// }