$breakpoints: (
  xxs: 420px,
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1440px
);

@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get(
      $breakpoints,
      $breakpoint
    ); // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}

@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get(
      $breakpoints,
      $breakpoint
    ); // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}

.pagination-lg {
  .page-item {
    padding: 15px;

    @include respond-below(sm) {
      padding: 3px;
    }

    .page-link {
      min-width: 50px;
      height: 50px;

      @include respond-below(sm) {
        min-width: 38px;
        height: 38px;
        padding: 0.55rem 0;
        line-height: 1.5;
        font-size: 0.8rem;
      }

      @include respond-below(xs) {
        min-width: 30px;
        height: 30px;
        line-height: 0.9;
        font-size: 0.76rem;
      }
    }
  }
}

.pagination-sm {
  .page-item {
    padding: 6px;

    @include respond-below(sm) {
      padding: 3px;
    }

    .page-link {
      min-width: 30px;
      height: 30px;
      // line-height: 0.9;
      font-size: 14px; //0.76rem;
      cursor: pointer;
    }
  }
}

.pagination {
  .page-link.prev {
    i::before {
      content: "\e606";
    }
  }

  .page-link.next {
    i::before {
      content: "\e605";
    }
  }

  .page-link.first {
    i::before {
      content: "\e074";
    }
  }

  .page-link.last {
    i::before {
      content: "\e06f";
    }
  }
}

.nav,
.pagination {
  padding-right: 0;
}