
.card-img .overlay {
    background: rgba(0,0,0,0.7);
    position: absolute;
    width: 200 !important;
    height: 150 !important;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }
  .card:hover {
    color: #000;
  }
  
  .card:hover .card-img:after .overlay {
    opacity: 1;
   
  }


.details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;

  

    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
  
  .card-img:hover .details{

    opacity: 1;
  }